import { Component, OnInit } from '@angular/core';
import { filter, first, switchMap } from 'rxjs';
import { UserService } from '../../../rc-api/services/user.service';
import { KindeAuthService } from '../kinde-auth.service';

@Component({
  selector: 'rc-auth-login-callback',
  styles: ['img { margin-bottom: 15rem; }'],
  template: `
    <div
      class="flex flex-column align-items-center justify-content-evenly h-100dvh bg-secondary"
    >
      <img
        src="/assets/img/logo.svg"
        width="200"
      />
    </div>
  `,
  standalone: true,
})
export class KindeAuthLoginCallbackComponent implements OnInit {
  constructor(
    private authService: KindeAuthService,
    private userService: UserService,
  ) {}

  public ngOnInit(): void {
    // Handle the login callback from Kinde, and update the user profile after login
    this.authService.handleLoginCallback().then(() => {
      this.authService.user$
        .pipe(
          filter((user) => user !== null),
          first(),
          switchMap((user) => {
            return this.userService.updateProfile({
              name: `${user!.given_name} ${user!.family_name}`,
            });
          }),
        )
        .subscribe();
    });
  }
}
